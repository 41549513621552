export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK Laboratory-Taiwan"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium specialty needles"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于TSK"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品介绍"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即交谈"])},
  "Training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培训平台"])},
  "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证诊所"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
  "about": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT IS TSK ?"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本TSK是专业设计活检针和特殊针的制造商"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK拥有 ISO 认证的无菌工厂，生产、销售和出口医疗设备。TSK更与日本和海外各种领域的医师合作进行产品联合开发，设计医师所需的规格产品。"])},
    "contribution1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK 提供优质的医疗设备<br>为国际社会做出贡献"])},
    "contribution2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本TSK Laboratory成立于1974年，企业理念为「透过提供优质的医疗设备为国际社会做出贡献」。<br>TSK从开发到制造，皆在整合生产系统的严格品质控制下完成，为全球各地的医疗机构提供优质、安全且令人安心的产品。<br>无论如何，TSK都致力于为世界各地的客户提供优质的产品。并继续为全世界患者及其家人的福祉而努力。"])},
    "contribution3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["川嶋健 Ken Kawashima,<br>President. TSK Laboratory, Japan"])},
    "Vision1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK专注于品质、创新和客户亲密度。我们的目标是提供安全、高品质的产品，使医师和专家能够为他们的病人提供更好的治疗。通过优化生产技术和紧随市场的开发革新，我们不断改进产品以符合在各领域医学专家治疗的方式。为提供更好的产品和解决方案，我们非常重视与客户联系。透过医师和执业医师的互动帮助我们获得产品反馈，这是我们不断改进产品和满足客户需求的开发过程中不可或缺的一部分。<br><br>在持续成长的同时，始终坚定自己的目标和追求。 TSK的愿景，是不断为医美、介入放射学、眼科行业提供新的产品创新和解决方案。"])},
    "Vision2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK努力为所有员工提供一个健康的工作场所，并承担起当地的社会责任。我们通过本地人才招聘，在公司推广家庭氛围的企业文化，从而向员工表达深切的感激，并确保大家在一个友好而熟悉的环境下工作。<br><br>值得自豪的是，不仅在TSK内部，日常工作中与我们共事的每个人，从业医师、相关从业者以及我们的分销商和医药合作伙伴，都能感受到来自于我们的尊重。 TSK的宗旨在基于对产品和解决方案的信任之上，与各位建立的一种长期稳定的合作关系。"])},
    "Needle1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2006年：推出超薄壁针"])},
    "Needle1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2008年：推出PRC和HPC针座"])},
    "Needle1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2009年：推出33G微针"])},
    "Needle1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2010年：推出CSH钝针"])},
    "Needle2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2013年：推出STERiGLIDE钝针"])},
    "Needle2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2014年：推出低死腔针座"])},
    "Needle2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016年：推出INViSIBLE NEEDLE"])},
    "Needle2-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年：推出不含BPA针头：不含双酚A、BADGE或二氧化钛"])}
  },
  "allProduct": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 年经验<br>创新技术针头"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致针至美"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCT"])},
    "tsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在TSK，我们非常重视开发新技术和产品创新。我们的目标是为医疗美容、介入放射学和眼科市场提供新的解决方案，在提高临床结果的同时提高患者的安全性和舒适度。多年来，我们从未停止过新技术的研发，不断努力研发符合我们信条的安全实用的产品解决方案。"])}
  },
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["型号规格"])},
  "STERiJECT": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特杰 尖针"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特杰 尖针<br>TSK STERiJECT™ Hypodermic Needle"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装规格：100 pc/盒<br>使用方法：本产品须由医师或医事人员操作。<br><br>卫部医器输字第037254号"])}
  },
  "STERiGLIDE": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特滑钝针"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特滑钝针<br>TSK STERiGLIDE® Cannula"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装规格：20 pc/盒 (皆附Needle Guide引导针)<br>使用方法：本产品须由医师或医事人员操作。<br><br>卫部医器输字第037253号"])}
  },
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多资讯"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "TrainingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK 提供线上培训平台之内容仅供医疗专业人士阅读探讨与学术交流。<br><br>欢迎联系，了解更多平台资讯"])},
  "intoTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进入培训平台"])},
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃园市龟山区文兴路146号3楼之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私权政策"])},
  "institute": {
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原厂认证医疗院所"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK来自日本原厂授权，精心铸造而成，以最高品质与最高标准生产，为医疗院所、医师与谘询师们提供专业服务。<br/><br/>至医疗院所请认明TSK原厂认证授权书。"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "North": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北部"])},
    "Middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中部"])},
    "South": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南部"])},
    "East": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["东部"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}