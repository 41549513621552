export const lang = [
  { name: "繁體", lang: "zh-TW" },
  { name: "简体", lang: "zh-CN" },
  { name: "English", lang: "en" },
];

export const tab = [
  { name: "About", to: "about" },
  { name: "Product", to: "AllProduct" },
  { name: "News", to: "news" },
  { name: "Training", to: "Training"},
  // { name: "Chat", to: "Chat"},
  { name: "Contact", to: "#contact" },
];