export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK Laboratory Taiwan"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium specialty needles"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於TSK"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品介紹"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即交談"])},
  "Training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓平台"])},
  "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認證診所"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
  "about": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT IS TSK ?"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本TSK是專業設計活檢針和特殊針的製造商"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK擁有 ISO 認證的無菌工廠，生產、銷售和出口醫療設備。TSK更與日本和海外各種領域的醫師合作進行產品聯合開發，設計醫師所需的規格產品。"])},
    "contribution1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK 提供優質的醫療設備<br>為國際社會做出貢獻"])},
    "contribution2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本TSK Laboratory成立於1974年，企業理念為「透過提供優質的醫療設備為國際社會做出貢獻」。<br>TSK從開發到製造，皆在整合生產系統的嚴格品質控制下完成，為全球各地的醫療機構提供優質、安全且令人安心的產品。<br>無論如何，TSK都致力於為世界各地的客戶提供優質的產品。並繼續為全世界患者及其家人的福祉而努力。"])},
    "contribution3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["川嶋健 Ken Kawashima,<br>President. TSK Laboratory, Japan"])},
    "Vision1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK專注於品質、創新和客戶親密度。我們的目標是提供安全、高品質的產品，使醫師和專家能夠為他們的病人提供更好的治療。通過優化生產技術和緊隨市場的開發革新，我們不斷改進產品以符合在各領域醫學專家治療的方式。為提供更好的產品和解決方案，我們非常重視與客戶聯繫。透過醫師和執業醫師的互動幫助我們獲得產品反饋，這是我們不斷改進產品和滿足客戶需求的開發過程中不可或缺的一部分。<br><br>在持續成長的同時，始終堅定自己的目標和追求。 TSK的願景，是不斷為醫美、介入放射學、眼科行業提供新的產品創新和解決方案。"])},
    "Vision2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK努力為所有員工提供一個健康的工作場所，並承擔起當地的社會責任。我們通過本地人才招聘，在公司推廣家庭氛圍的企業文化，從而向員工表達深切的感激，並確保大家在一個友好而熟悉的環境下工作。<br><br>值得自豪的是，不僅在TSK內部，日常工作中與我們共事的每個人，從業醫師、相關從業者以及我們的分銷商和醫藥合作夥伴，都能感受到來自於我們的尊重。 TSK的宗旨在基於對產品和解決方案的信任之上，與各位建立的一種長期穩定的合作關係。"])},
    "Needle1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020年：推出不含BPA針頭：不含雙酚A、BADGE或二氧化鈦"])},
    "Needle1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016年：推出INViSIBLE NEEDLE"])},
    "Needle1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2014年：推出低死腔針座"])},
    "Needle1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2013年：推出STERiGLIDE鈍針"])},
    "Needle2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2010年：推出CSH鈍針"])},
    "Needle2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2009年：推出33G微針"])},
    "Needle2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2008年：推出PRC和HPC針座"])},
    "Needle2-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2006年：推出超薄壁針"])}
  },
  "allProduct": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 年經驗<br>創新技術針頭"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致針至美"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCT"])},
    "tsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在TSK，我們非常重視開發新技術和產品創新。我們的目標是為醫療美容、介入放射學和眼科市場提供新的解決方案，在提高臨床結果的同時提高患者的安全性和舒適度。多年來，我們從未停止過新技術的研發，不斷努力研發符合我們信條的安全實用的產品解決方案。"])}
  },
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["型號規格"])},
  "STERiJECT": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特傑 尖針"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特傑 尖針<br>TSK STERiJECT™ Hypodermic Needle"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裝規格：100 pc/盒<br>使用方法：本產品須由醫師或醫事人員操作。<br><br>衛部醫器輸字第037254號"])}
  },
  "STERiGLIDE": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特滑鈍針"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["史特滑鈍針<br>TSK STERiGLIDE® Cannula"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裝規格：20 pc/盒 (皆附Needle Guide引導針)<br>使用方法：本產品須由醫師或醫事人員操作。<br><br>衛部醫器輸字第037253號"])}
  },
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多資訊"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
  "TrainingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK 提供線上培訓平台之內容僅供醫療專業人士閱讀探討與學術交流<br><br>歡迎聯繫，了解更多平台資訊"])},
  "intoTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進入培訓平台"])},
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺灣"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桃園市龜山區文興路146號3樓之2"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海"])}
  },
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])},
  "institute": {
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原廠認證醫療院所"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK來自日本原廠授權，精心鑄造而成，以最高品質與最高標準生產，為醫療院所、醫師與諮詢師們提供專業服務。<br/><br/>至醫療院所請認明TSK原廠認證授權書。"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "North": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["北部"])},
    "Middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中部"])},
    "South": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南部"])},
    "East": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["東部"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}