<template>
  <div class="news-article">
    <div v-if="n">
      <img class="w-100" :src="n.articleImg" />
        <div class="container py-5">
          <h3 class="text-start">
            <span class="px-2 mx-2 text-primary rounded">NEWS</span>
          </h3>
          <p class="float-end">{{ n.time }}</p>
          <div class="bg-white text-start my-5">
            <component :is="comp"></component>
          </div>
      </div>
    </div>
  </div>
  <!-- 聯絡我們 -->
    <div id="contact" class="bg-primary">
      <div class="container py-5 text-start text-white col">
        <p class="h4 fw-bold">遠京企業股份有限公司 MYGUARD CO., LTD.<br></p>
        <p class="mt-3">臺灣Taiwan</p>
        <p>地址：臺灣桃園市龜山區文興路146號3樓之2</p>
        <p>3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan</p>
        <p>電話: <a href="tel:+886-3327-0080" style="color: white;">+886 3 327-0080</a></p>
        <p>Email: <a href="mailto: info@myguard.com" style="color: white;">info@myguard.com</a></p>
      </div>
    </div>
</template>
<script>
import news from "@/config/news.js";
import { defineAsyncComponent } from "vue";
export default {
  name: "NewsArticle",
  data() {
    return { news: news, n: null };
  },
  computed: {
    comp() {
      return defineAsyncComponent(() => import(`@/components/news/News${this.n.id}.vue`));
    },
  },
  mounted() {
    const n = this.news.find((n) => n.id == this.$route.params.id);
    this.n = n;
  },
};
</script>
<style lang=""></style>
