<template>
  <div class="AllProduct">
    <div class="container center pt-4 h4 text-black lh-base">
      <p v-html="$t('allProduct.title')"></p>
    </div> 
    <div class="container center" style="height: 20px; background-color: white">
      <div class="h-25" style="width: 50px; background-color: rgba(0,113,187,1)"></div>
    </div>
    <div class="container center pt-4 lh-lg text-dark-gray" style="width: 60%; font-size: 16px;" >
      <p v-html="$t('allProduct.tsk')"></p>
    </div> 
    <div class="container center pt-4 h4 text-primary lh-base">
      <p v-html="$t('allProduct.title2')"></p>
    </div> 
    <div class="container">
        <img src="@/assets/images/product1.png" class="img-fluid"/>
    </div>
    <div class="h5 p-3 bg-primary text-center text-white" style="height: 60px;">
        <p>PREMIUM SPECIALTY NEEDLES</p>
    </div>
    <div class="container p-5">
      <div class="row justify-content-center flex-wrap">
        <div class="col-lg-2 col-md-3 col-4">
          <img src="@/assets/images/product2.png" class="img-fluid"/><br/><div class="container text-primary">TSKiD™ 薄壁技術</div>
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="@/assets/images/product3.png" class="img-fluid"/><br/><div class="container text-primary">媲美34G的針頭</div>
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="@/assets/images/product4.png" class="img-fluid"/><br/><div class="container text-primary">圓頂形鈍針頭</div>
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="@/assets/images/product5.png" class="img-fluid"/><br/><div class="container text-primary">針孔靠近尖端</div>
        </div>
        <div class="col-lg-2 col-md-3 col-4">
          <img src="@/assets/images/product6.png" class="img-fluid"/><br/><div class="container text-primary">專有表面處理</div>
        </div>
      </div>
    </div>
    <div id="product" class="bg-light">
      <div class="container p-5">
        <div class="container center pt-4 h4 text-primary lh-base">
          <p v-html="$t('allProduct.title3')"></p>
        </div> 
        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 col-md-5 col-lg-4" v-for="(p, index) in product" :key="index">
            <div class="product-card shadow-sm p-3 mb-5 bg-white rounded">
              <a :href="'/product/' + p.name" target="_self">
                <img class="img-sm" :src="p.src"/>
              </a>
              <div class="product-description mt-3">
                <p style="font-weight: bold;" v-html="p.usDesc"></p>
                <p style="font-weight: bold;" v-html="$t(`${p.name}.name`)"></p>
                <a class="mt-auto fw-bold text-decoration-none" :href="'/product/' + p.name">
                  VIEW ALL <i class="fa-solid fa-angles-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <!-- 聯絡我們 -->
    <div id="contact" class="bg-primary">
      <div class="container py-5 text-start text-white col">
        <p class="h4 fw-bold">遠京企業股份有限公司 MYGUARD CO., LTD.<br></p>
        <p class="mt-3">臺灣Taiwan</p>
        <p>地址：臺灣桃園市龜山區文興路146號3樓之2</p>
        <p>3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan</p>
        <p>電話: <a href="tel:+886-3327-0080" style="color: white;">+886 3 327-0080</a></p>
        <p>Email: <a href="mailto: info@myguard.com" style="color: white;">info@myguard.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import product from "@/config/product.js";
export default {
  name: "AllProduct",
  data() {
    return {
      product: product
    };
  },
};
</script>

<style scoped>
.product-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; /* 確保內容不會溢出 */
  height: 100%; /* 讓卡片填滿父容器高度 */
}

.product-description {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden; /* 確保內容不會溢出 */
}

.product-description p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* 單行顯示文字，超出部分以省略號表示 */
}

@media (max-width: 576px) {
  .product-card {
    height: auto; /* 讓高度自適應 */
  }

  .product-description p {
    white-space: normal; /* 在小屏幕上讓文字自動換行 */
    text-overflow: clip; /* 移除省略號 */
  }
}
</style>
