const news = [
  { // 文章
    id: "05",
    title: "【 TSK | ISDS 國際皮膚外科學會】",
    time: "2024/08/28",
    link: "/news/05",
    src: require("@/assets/images/news/05/news_01.jpg"),
    component: () => import("@/components/news/News05.vue"),
    video: false,
  },
  { // 影片
    id: "04",
    title: "【TSK Laboratory Taiwan】",
    time: "2024/08/13",
    src: require("@/assets/images/news/04/news_01.jpg"),
    link: "https://www.youtube.com/embed/g5UNQ7OZQSk",
    video: true,
  },
  { // 文章
    id: "03",
    title: "【 TSK | IMCAS 2024 】",
    time: "2024/02/01",
    link: "/news/03",
    src: require("@/assets/images/news/03/news_02.jpg"),
    component: () => import("@/components/news/News03.vue"),
    video: false,
  },
  { // 文章
    id: "02",
    title: "【 TSK | 臺灣代理授權 】",
    time: "2024/07/31",
    link: "/news/02",
    src: require("@/assets/images/news/02/news_02.jpg"),
    component: () => import("@/components/news/News02.vue"),
    video: false,
  },
  { // 文章
    id: "01",
    title: "【 TSK | 仿冒提醒聲明 】",
    time: "2024/07/31",
    link: "/news/01",
    src: require("@/assets/images/news/01/news_02.jpg"),
    component: () => import("@/components/news/News01.vue"),
    video: false,
  },

  // { // 影片
  //   id: "00",
  //   title: "【2021 臺灣醫用雷射光電學會】",
  //   time: "2021/03/27",
  //   src: require("@/assets/images/zh-TW/news/news_2.png"),
  //   link: "https://www.youtube.com/embed/9KMuw2rDCSw",
  //   video: true,
  // },
]
export default news