<template>
  <div class="p-2 bg-black text-white" style="height: 40px;">
    <p>Copyright 2024 © TSK Laboratory Taiwan</p>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
