<template>
  <VideoModal :title="title" :link="link"></VideoModal>
  <div class="container">
        <img src="@/assets/images/news_banner.png" class="img-fluid"/>
  </div>
  <div class="news container my-5 bg-gray2">
    <h1 class="h2 text-start py-3 px-3">NEWS</h1>
    <div class="row align-items-start">
      <div class="col-lg-4 col-sm-10" v-for="(n, index) in news" :key="index">
        <NewsCard :n="n" @click="showVideo(n)"></NewsCard>
      </div>
    </div>
  </div>
  <!-- 聯絡我們 -->
  <div id="contact" class="bg-primary">
      <div class="container py-5 text-start text-white col">
        <p class="h4 fw-bold">遠京企業股份有限公司 MYGUARD CO., LTD.<br></p>
        <p class="mt-3">臺灣Taiwan</p>
        <p>地址：臺灣桃園市龜山區文興路146號3樓之2</p>
        <p>3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan</p>
        <p>電話: <a href="tel:+886-3327-0080" style="color: white;">+886 3 327-0080</a></p>
        <p>Email: <a href="mailto: info@myguard.com" style="color: white;">info@myguard.com</a></p>
      </div>
    </div>
</template>
<script>
import VideoModal from "@/components/VideoModal.vue";
import NewsCard from "@/components/NewsCard.vue";
import news from "@/config/news.js"
export default {
  name: "News",
  components: {
    VideoModal,
    NewsCard,
  },
  data() {
    return {
      title: "",
      link: "",
      news: news,
    }
  },
  mounted() {
  window.scrollTo(0, 0);
  },
  methods: {
    showVideo(n) {
      this.title = n.title
      this.link = n.link
    }
  }
};
</script>
<style lang=""></style>
