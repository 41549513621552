<template>
  <div class="header">
    <nav id="navbar" class="navbar navbar-expand-lg navbar-light" style="background-color: #fff; box-shadow:8px 8px 8px #0071BB33">
      <div class="container-sm">
        <div class="navbar-brand p-0">
          <div class="row align-items-center">
            <div class="col">
              <a :href="`/`">
                <img src="@/assets/logo.png" width="96" height="35"/>
              </a>
            </div>
            <div class="col">
              <div class="collapse navbar-collapse text-primary" style="font-size: small;">{{$t('logo')}}</div>
            </div>
          </div>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- 各個tab -->
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item" v-for="(t, index) in tab" :key="index">
              <a :id="`tab-${index}`" class="nav-link active-page" :href="`/${t.to}`">{{
                $t(t.name)
              }}</a>
            </li>
          </ul>
          <!-- 社交平台 -->
          <form class="d-flex center">
            <ul class="navbar-nav social-nav">
              <li class="m-1 center">
                <a href="https://www.facebook.com/TSKlabTaiwan" target="_blank">
                  <!-- <i class="fa-brands fa-square-facebook"></i> -->
                  <img class="w-100 h-100" src="@/assets/images/facebook.png" />
                </a>
              </li>
              <li class="m-1 center">
                <a href="https://www.instagram.com/tsk_taiwan/?igsh=eXZoZnF6aGIwaGF5" target="_blank">
                  <!-- <i class="fa-brands fa-square-facebook"></i> -->
                  <img class="w-100 h-100" src="@/assets/images/ig.png" />
                </a>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { lang, tab } from "@/config/headerConfig.js";
export default {
  name: "Header",
  data() {
    return {
      langs: lang,
      tab: tab,
    };
  },
  mounted() {
    let currentActiveLink = null; // 保存當前的活動導覽連結
    let originalActiveLink = null; // 保存原始的活動導覽連結

    const setNavLinkActive = (link) => {
        // 將活動導覽連結設置為活動狀態，如果存在
        if (link) {
            link.classList.add('active');
            currentActiveLink = link;
        }
    };

    const removeNavLinkActive = (link) => {
        // 移除導覽連結的活動狀態，如果存在
        if (link) {
            link.classList.remove('active');
        }
    };

    const checkAndSetNavLink = (url) => {
        // 檢查並設置導覽連結為活動狀態
        const navLinks = document.querySelectorAll('.active-page');
        let foundMatch = false;
        
        navLinks.forEach((link) => {
            const linkUrl = link.getAttribute('href');
            
            if (url === linkUrl) {
                setNavLinkActive(link);
                foundMatch = true;
            } else {
                removeNavLinkActive(link);
            }
        });

        return foundMatch;
    };

    const setCurrentNavLinkByURL = (url) => {
        // 根據URL設置活動導覽連結
        let matchingLink = null;

        if (/^\/(product|news)\//.test(url)) {
            if (url.startsWith('/product/')) {
                matchingLink = document.querySelector('#tab-1');
            } else if (url.startsWith('/news/')) {
                matchingLink = document.querySelector('#tab-2');
            }
        } else {
            matchingLink = url === "/" ? document.querySelector('#tab-1') : document.querySelector('#tab-1');
        }

        setNavLinkActive(matchingLink);
    };

    window.addEventListener("scroll", () => {
        // 滾動事件處理程序
        const navbar = document.getElementById("navbar");

        if (window.scrollY > navbar.offsetHeight) {
            navbar.classList.add("fixed-top");
            document.body.style.paddingTop = navbar.offsetHeight + "px";
        } else {
            navbar.classList.remove("fixed-top");
            document.body.style.paddingTop = "0";
        }

        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            // 到達頁面底部時
            const tab2Link = document.querySelector('#tab-4');
            removeNavLinkActive(document.querySelector('.active-page.active'));
            setNavLinkActive(tab2Link);
        } else {
            // 离开页面底部时
            setNavLinkActive(originalActiveLink);
            removeNavLinkActive(document.querySelector('#tab-4'));
        }
    });

    const currentUrl = window.location.pathname;
    const foundMatch = checkAndSetNavLink(currentUrl);

    if (!foundMatch) {
        setCurrentNavLinkByURL(currentUrl);
    }

    // 保存原始的活動導覽連結
    originalActiveLink = currentActiveLink;
  },
};
</script>
<style lang="scss">
#lang {
  .border-gray {
    line-height: 0.8;
  }
  label.active {
    color: var(--bs-primary);
    font-weight: 700;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    font-size: 0.9rem;
    a {
      text-decoration: none;
      color: black;
    }
  }
}
.navbar-nav {
  .active {
    color: #0071bb !important;
    font-weight: bold;
  }
}
.social-nav {
  li {
    width: 30px;
    height: 30px;
  }
}
</style>
