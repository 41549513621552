export default {
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK Laboratory-Taiwan"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Specialty Needles"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About TSK"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Now"])},
  "Training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Platform"])},
  "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified Clinics"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "about": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT IS TSK?"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK Japan: Specialist Manufacturer of Biopsy and Specialty Needles"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK operates an ISO-certified sterile factory, producing, selling, and exporting medical devices. Collaborating with physicians both in Japan and internationally, TSK co-develops products to meet specific medical needs."])},
    "contribution1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK provides high-quality medical devices<br>contributing to global healthcare"])},
    "contribution2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founded in 1974, TSK Japan Laboratory is dedicated to contributing to global healthcare through high-quality medical devices. All production, from development to manufacturing, is conducted under strict quality control in an integrated production system, ensuring safe and reliable products for medical institutions worldwide. TSK remains committed to delivering quality products for the well-being of patients and their families globally."])},
    "contribution3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ken Kawashima,<br>President, TSK Laboratory, Japan"])},
    "Vision1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK focuses on quality, innovation, and customer intimacy. Our goal is to provide safe, high-quality products that enable physicians and specialists to offer better treatments to their patients. By optimizing production technologies and staying abreast of market developments, we continuously improve our products to meet the needs of medical experts across various fields. We value customer feedback, gathered through interactions with physicians and practitioners, as an essential part of our development process to continually refine our offerings."])},
    "Vision2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK is committed to providing a healthy workplace for all employees and fulfilling local social responsibilities. By recruiting local talent and promoting a family-oriented corporate culture, we express deep appreciation to our employees, ensuring they work in a friendly and familiar environment. We are proud that this respect extends not only within TSK but also to all our partners, including physicians, practitioners, distributors, and medical collaborators, fostering long-term, stable relationships based on trust in our products and solutions."])},
    "Needle1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2006: Launched Ultra Thin Wall Needles"])},
    "Needle1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2008: Launched PRC and HPC Needle Hubs"])},
    "Needle1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2009: Launched 33G Micro Needle"])},
    "Needle1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2010: Launched CSH Cannula"])},
    "Needle2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2013: Launched STERiGLIDE Cannula"])},
    "Needle2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2014: Launched Low Dead Space Needle Hub"])},
    "Needle2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2016: Launched Invisible Needle"])},
    "Needle2-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020: Launched BPA-Free Needle: Free from BPA, BADGE, or Titanium Dioxide"])}
  },
  "allProduct": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 Years of Experience<br>Innovative Needle Technology"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfecting the Needle"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCTS"])},
    "tsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At TSK, we highly value the development of new technologies and product innovations. Our goal is to provide new solutions for the medical aesthetics, interventional radiology and ophthalmology markets, improving clinical outcomes while enhancing patient safety and comfort. Over the years, we have never ceased developing new technologies, continually striving to create safe and practical product solutions that align with our principles."])}
  },
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Specifications"])},
  "STERiJECT": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STERiJECT Hypodermic Needle"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STERiJECT Hypodermic Needle<br>TSK STERiJECT™ Hypodermic Needle"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging: 100 pcs/box<br>Usage: This product must be used by a physician or medical personnel.<br><br>Medical Device Import Number: 037254"])}
  },
  "STERiGLIDE": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STERiGLIDE Cannula"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STERiGLIDE Cannula<br>TSK STERiGLIDE® Cannula"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging: 20 pcs/box (each includes a Needle Guide)<br>Usage: This product must be used by a physician or medical personnel.<br><br>Medical Device Import Number: 037253"])}
  },
  "More Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])},
  "See More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
  "TrainingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK provides an online training platform exclusively for medical professionals for discussion and academic exchange.<br><br>Contact us to learn more about the platform."])},
  "intoTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Training Platform"])},
  "contact": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "addressV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd Floor, No. 146, Wenxing Road, Guishan District, Taoyuan City"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "country2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanghai"])}
  },
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "institute": {
    "introTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified Medical Institutions"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TSK is authorized by the original Japanese manufacturer, producing with the highest quality and standards, providing professional services to medical institutions, physicians, and consultants.<br/><br/>Look for the TSK certification when visiting medical institutions."])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "North": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North"])},
    "Middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle"])},
    "South": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South"])},
    "East": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n!!"])}
}