const product = [
  {
    name: 'STERiJECT',
    usDesc: 'TSK STERiJECT™<br>Hypodermic Needle',
    src: require("@/assets/images/product8.jpg"),
  },
  {
    name: 'STERiGLIDE',
    usDesc: 'TSK STERiGLIDE® Cannula',
    src: require("@/assets/images/product9.jpg"),
  },
]

export default product