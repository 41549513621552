<template>
  <div class="Training">
    <div class="container">
      <img src="@/assets/images/news_banner.png" class="img-fluid" />
    </div>
    <div id="TSK">
      <div class="container center my-5 text-primary">
        <h5>
          <p v-html="$t('TrainingDescription')"></p>
        </h5>
      </div>
      <div class="container" style="height: 120px;">
        <a class="mt-0 p-2 px-3 btn border-10 text-primary fw-bold"
          href="https://tsk.share-guru.net/base_sgweb/general/user/login" target="_blank" style="font-size: 1.5em;">
          <span v-html="$t('intoTraining')"></span><i class="fa-solid fa-angles-right"></i>
        </a>
      </div>
    </div>
    <!-- 聯絡我們 -->
    <div id="contact" class="bg-primary">
      <div class="container py-5 text-start text-white col">
        <p class="h4 fw-bold">遠京企業股份有限公司 MYGUARD CO., LTD.<br></p>
        <p class="mt-3">臺灣Taiwan</p>
        <p>地址：臺灣桃園市龜山區文興路146號3樓之2</p>
        <p>3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan</p>
        <p>電話: <a href="tel:+886-3327-0080" style="color: white;">+886 3 327-0080</a></p>
        <p>Email: <a href="mailto: info@myguard.com" style="color: white;">info@myguard.com</a></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Training",
};
</script>
