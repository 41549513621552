import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'
import Home from '../views/Home.vue'
import Privacy from '../views/Privacy.vue'
import News from '../views/News.vue'
import NewsArticle from '../views/NewsArticle.vue'
import Product from '../views/Product.vue'
import Training from '../views/Training.vue'
import AllProduct from '../views/AllProduct.vue'
//import Chat from '../views/Chat.vue'
// import Institute from '../views/Institute.vue'
// import soon from '../views/soon.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: AllProduct
  },
  {
    path: '/AllProduct',
    name: 'AllProduct',
    component: AllProduct
  },
  {
    path: '/about',
    name: 'about',
    component: Home
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/news/:id',
    name: 'NewsArticle',
    component: NewsArticle
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product
  },
  {
    path: '/Training/',
    name: 'Training',
    component: Training
  },
  // {
  //   path: '/Chat',
  //   name: 'Chat',
  //   component: Chat
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const defaultTitle = i18n.global.t("Title")
  document.title = to.meta.title ? `${to.meta.title} | ${defaultTitle}` : defaultTitle;
  next();
})

export default router
