<template>
  <div class="home">
      <div class="container">
        <img src="@/assets/images/about1.png" class="img-fluid"/>
      </div>
    <!-- What is TSK -->
    <div id="about">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-6 col-sm-10 center">
            <iframe class="w-100" src="https://www.youtube.com/embed/g5UNQ7OZQSk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div class="col-lg-6 col-sm-10 text-start center">
            <div class="row row-cols-1">
              <div class="col p-5">
                <p class="h2 text-primary" v-html="$t('about.title1')"></p>
                <p class="text-primary" style="font-size: 20px;" v-html="$t('about.title2')"></p>
                <p class="text-dark-gray" style="font-size: 14px;" v-html="$t('about.description')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CEO圖-->
    <div id="CEO">
      <div class="container py-5">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-10 center">
            <img src="@/assets/images/aboutCEO.jpg" class="img-fluid"/>
          </div>
          <div class="col-lg-7 col-sm-10 text-start center">
            <div class="row row-cols-1">
              <div class="col p-5">
                <p class="h2 text-primary" v-html="$t('about.contribution1')"></p>
                <div style="height: 20px;"></div>
                <p class="text-dark-gray" style="font-size: 14px;" v-html="$t('about.contribution2')"></p>
                <div style="height: 20px;"></div>
                <p class="text-black" style="font-size: 14px;" v-html="$t('about.contribution3')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Vision & values-->
    <div id="Vision">
      <div class="container p-5">
        <h1 class="h2 text-start">Vision & values<span class="icon-report_close" style="vertical-align: middle; margin-left: 5px;"></span></h1>

        <div class="row">
          <div class="col-lg-6 col-sm-10 text-start text-dark-gray" style="font-size: 14px;">
            <p v-html="$t('about.Vision1')"></p>
          </div>
          <div class="col-lg-6 col-sm-10 text-start text-dark-gray" style="font-size: 14px;">
            <p v-html="$t('about.Vision2')"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 工廠圖 -->
    <div id="">
      <div class="container">
        <img src="@/assets/images/about3.png" class="img-fluid"/>
      </div>
    </div>
    <!-- Needle innovations-->
    <div id="Needle">
      <div class="container p-5">
        <h1 class="h2 text-start">Needle innovations<span class="icon-report_close" style="vertical-align: middle; margin-left: 5px;"></span></h1>

        <div class="row">
          <div class="col-lg-6 col-sm-10 text-start text-dark-gray" style="font-size: 14px;">
            <ul>
              <li>{{ $t("about.Needle1-1") }}</li>
              <li>{{ $t("about.Needle1-2") }}</li>
              <li>{{ $t("about.Needle1-3") }}</li>
              <li>{{ $t("about.Needle1-4") }}</li>
            </ul>
          </div>
          <div class="col-lg-6 col-sm-10 text-start text-dark-gray" style="font-size: 14px;">
            <ul>
              <li>{{ $t("about.Needle2-1") }}</li>
              <li>{{ $t("about.Needle2-2") }}</li>
              <li>{{ $t("about.Needle2-3") }}</li>
              <li>{{ $t("about.Needle2-4") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- TSK non-stop-->
    <div id="TSK">
      <div class="container center my-5 text-primary">
      <h5>TSK 從未停止新技術的研發<br><br>不斷努力開發安全和實用的產品解決方案<br><br>正如我們的信念 : 致針至美</h5>
      </div>  
    </div>
    <!-- 聯絡我們 -->
    <div id="contact" class="bg-primary">
      <div class="container py-5 text-start text-white col">
        <p class="h4 fw-bold">遠京企業股份有限公司 MYGUARD CO., LTD.<br></p>
        <p class="mt-3">臺灣Taiwan</p>
        <p>地址：臺灣桃園市龜山區文興路146號3樓之2</p>
        <p>3F.-2, No.146, Wenxing Rd.,Guishan, Taoyuan City 33377, Taiwan</p>
        <p>電話: <a href="tel:+886-3327-0080" style="color: white;">+886 3 327-0080</a></p>
        <p>Email: <a href="mailto: info@myguard.com" style="color: white;">info@myguard.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import product from "@/config/product.js";

export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      product: product
    };
  },
};
</script>

<style lang="scss">
#contact {
  p {
    margin: 0;
  }
}
</style>
